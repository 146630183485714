import { Close } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAllContexts } from "../../../../services/app/api";
import { isValidURL } from "../../../../services/generalFunctions";
import { handleChangEditPrompt } from "../../../../store/collectionsSlice";
import { useAuth } from "../../../Auth/AuthContext";
import { useThemeContext } from "../../../Themes/ThemeContextProvider";
import FileUploadButton from "../../../UI/FileUploadButton";
import FilledTextField from "../../../UI/FilledTextField";
import {
  ConfigureIcon,
  FileUpload,
  QuestionIcon,
  TagIcon,
} from "../../../UI/IconPack";
import MuiSelect from "../../../UI/MuiSelect";
import SuggestionField from "../../../UI/SuggestionField";
import PromptSettings from "./PromptSettings";

const Hr = () => <div className="border-b-1 my-3 border-primary" />;

const PromptData = (props) => {
  const { t, i18n } = useTranslation();
  const { colors } = useThemeContext();
  const modelsVersionsList = useSelector(
    (state) => state.collectionsSlice.modelsVersionsList
  );
  const editPromptsList = useSelector(
    (state) => state.collectionsSlice.editPromptsList
  );
  const { discardChangesHandler, resetPromptData } = props;
  const promptData =
    editPromptsList.filter((prompt) => prompt?.isActiveTab)[0] || {};
  const promptList = promptData?.prompt_text?.prompt_data;
  const allowProcessType =
    modelsVersionsList?.filter((i) => i?.id === promptData?.llmVersion)[0]
      ?.model_type === "Image";
  const canAcceptImages =
    modelsVersionsList?.filter((i) => i?.id === promptData?.llmVersion)[0]
      ?.name !== "stability";
  const dispatch = useDispatch();
  const { activeTeam } = useAuth();
  const [contextData, setContextData] = useState([]);
  const [editImage, setEditImage] = useState("");
  const [maskImage, setMaskImage] = useState("");
  const [editImageFile, setEditImageFile] = useState("");
  const [maskImageFile, setMaskImageFile] = useState("");
  const [uploadType1, setUploadType1] = useState("file");
  const [uploadType2, setUploadType2] = useState("file");
  const [basePrompt, setBasePrompt] = useState(promptList[0]?.action_val || "");
  const [variableMenu, setVariableMenu] = useState([]);
  const [hideSettings, setHideSettings] = useState(true);

  const [errors, setErrors] = useState({
    editImage: "",
    maskImage: "",
  });

  useEffect(() => {
    setHideSettings(promptData?.id?.includes("newPromptId"));
    setBasePrompt(promptList[0]?.action_val || "");
    promptData?.variables?.length &&
      setVariableMenu(
        promptData?.variables.map(({ variable_key }) => ({
          title: variable_key,
          onClick: () => {},
          icon: (
            <span className="text-tertiary">
              {" "}
              <TagIcon />{" "}
            </span>
          ),
        }))
      );
  }, [promptData?.id, promptData?.variables?.length, resetPromptData]);

  useEffect(() => {
    if (isValidURL(promptData?.image_data)) {
      setEditImage(promptData?.image_data);
      setEditImageFile("");
    } else {
      setEditImageFile(promptData?.image_data);
      setEditImage("");
    }
    if (isValidURL(promptData?.mask_image)) {
      setMaskImage(promptData?.mask_image);
      setMaskImageFile("");
    } else {
      setMaskImageFile(promptData?.mask_image);
      setMaskImage("");
    }
  }, [promptData?.mask_image, promptData?.image_data]);
  useEffect(() => {
    let params = {
      page: 1,
      page_size: 500,
    };
    getAllContexts({ team_id: activeTeam, params }).then((res) =>
      setContextData(res.items)
    );
  }, []);

  const PromptBar = ({ data, index }) => {
    const [name, setName] = useState(data?.name);
    const [value, setValue] = useState(data?.action_val);
    const handleChangeFields = (e, field) => {
      let promptDataChange = promptList.map((item) => ({ ...item }));
      promptDataChange[index][field] = e.target.value;
      let data = {
        ...promptData,
        prompt_text: {
          ...promptData?.prompt_text,
          prompt_data: promptDataChange,
        },
      };
      dispatch(handleChangEditPrompt(data));
    };
    const handleDeletePrompt = () => {
      let promptDataChange = promptList.filter((item, ind) => ind !== index);
      let data = {
        ...promptData,
        prompt_text: {
          ...promptData?.prompt_text,
          prompt_data: promptDataChange,
        },
      };
      dispatch(handleChangEditPrompt(data));
    };
    return (
      <>
        {index !== 0 && (
          <div
            key={index}
            className="border-1 border-secondary bg-secondary flex rounded-lg first:mt-0 last:mb-0 my-4"
          >
            <div className="p-1 text-[10px] flex justify-center items-center  bg-brand-secondary rounded-tl-lg rounded-bl-lg ">
              {index}
            </div>
            <div className="w-full">
              <div className="px-2 py-3 flex gap-2">
                <div className="w-[40%]">
                  <SuggestionField
                    menuListItems={variableMenu}
                    label={
                      <span className="text-secondary text-sm font-medium">
                        Key
                      </span>
                    }
                    size={"small"}
                    fullWidth
                    value={name}
                    onChange={(e, value = "") => {
                      setName(value ? value : e.target.value);
                    }}
                    onBlur={(e) => {
                      if (!e.target.value.endsWith(" /")) {
                        handleChangeFields(e, "name");
                      }
                    }}
                    placeholder={"Enter key"}
                    endIcon={
                      <Tooltip title={t("what is prompt key")}>
                        <span>
                          <QuestionIcon />
                        </span>
                      </Tooltip>
                    }
                  />
                </div>
                <div className="w-full">
                  <SuggestionField
                    menuListItems={variableMenu}
                    label={
                      <span className="text-secondary text-sm font-medium">
                        Value
                      </span>
                    }
                    value={value}
                    size={"small"}
                    onChange={(e, value = "") =>
                      setValue(value ? value : e.target.value)
                    }
                    onBlur={(e) => {
                      if (!e.target.value.endsWith(" /")) {
                        handleChangeFields(e, "action_val");
                      }
                    }}
                    placeholder={"Enter Value"}
                    endIcon={
                      <Tooltip title={t("what is prompt value")}>
                        <span>
                          <QuestionIcon />
                        </span>
                      </Tooltip>
                    }
                  />
                </div>
                <div>
                  <IconButton size="small" onClick={handleDeletePrompt}>
                    <Close fontSize="small" className="text-tertiary" />
                  </IconButton>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const handleAddMorePrompt = () => {
    let promptDataChange = promptList.map((item) => ({ ...item }));
    promptDataChange.push({
      name: "",
      action_val: "",
    });
    let data = {
      ...promptData,
      prompt_text: {
        ...promptData?.prompt_text,
        prompt_data: promptDataChange,
      },
    };
    dispatch(handleChangEditPrompt(data));
  };

  const validateImageURL = (e, name) => {
    let url = e.target.value;
    if (!isValidURL(url)) {
      if (name === "image_data") {
        setEditImage("");
        setErrors((ini) => ({ ...ini, editImage: "Please enter valid URL" }));
      }
      if (name === "mask_image") {
        setEditImage("");
        setErrors((ini) => ({ ...ini, maskImage: "Please enter valid URL" }));
      }
      return;
    }
    let data = {
      ...promptData,
      [name]: url,
    };
    dispatch(handleChangEditPrompt(data));
  };

  const handleUploadInputImage = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setEditImageFile(selectedFile);
      let data = {
        ...promptData,
        image_data: selectedFile,
      };
      dispatch(handleChangEditPrompt(data));
    }
  };
  const handleUploadMaskImage = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setMaskImageFile(selectedFile);
      let data = {
        ...promptData,
        mask_image: selectedFile,
      };
      dispatch(handleChangEditPrompt(data));
    }
  };

  const handleBasePromptBlur = (e) => {
    let promptDataChange = promptList.map((item) => ({ ...item }));
    promptDataChange[0]["name"] = "action";
    promptDataChange[0]["action_val"] = e.target.value;
    let data = {
      ...promptData,
      prompt_text: {
        ...promptData?.prompt_text,
        prompt_data: promptDataChange,
      },
    };
    dispatch(handleChangEditPrompt(data));
  };

  return (
    <Grid px={10} py={2} container spacing={2}>
      {allowProcessType && promptData?.processType === "edit" && (
        <>
          <Grid item md={3.5}>
            <div className={"text-secondary font-semibold text-sm"}>
              Input image
            </div>
            <div className={"text-tertiary text-sm"}>
              {t("what is input image")}
            </div>
          </Grid>
          <Grid item md={8.5}>
            <div className="w-full flex flex-col gap-2 px-4 py-5 bg-secondary border-1 border-secondary rounded-lg ">
              <div className={"text-secondary font-semibold text-sm"}>
                Input image
              </div>
              <Select
                size="small"
                fullWidth
                value={uploadType1}
                onChange={(e) => setUploadType1(e.target.value)}
                className="bg-primary"
                sx={{
                  borderRadius: "8px",
                }}
              >
                <MenuItem
                  sx={{
                    background: colors.borderSecondary,
                    color: colors.textSecondary,
                    "&:hover": {
                      background: colors.bgSecondary,
                    },
                  }}
                  value="file"
                >
                  Upload From Your Computer
                </MenuItem>
                {canAcceptImages && (
                  <MenuItem
                    sx={{
                      background: colors.borderSecondary,
                      color: colors.textSecondary,
                      "&:hover": {
                        background: colors.bgSecondary,
                      },
                    }}
                    value="url"
                  >
                    Add image from URL
                  </MenuItem>
                )}
              </Select>
              {uploadType1 == "file" && (
                <div className="w-full">
                  <FileUploadButton
                    id={"inputImage"}
                    value={editImageFile}
                    onChange={handleUploadInputImage}
                    accept={".png"}
                    variant="primary"
                  >
                    <span className="flex items-center gap-4 justify-center">
                      <FileUpload /> Upload input image
                    </span>
                  </FileUploadButton>
                </div>
              )}
              {uploadType1 === "url" && (
                <div className="w-full">
                  <FilledTextField
                    size="small"
                    fullWidth
                    value={editImage}
                    onChange={(e) => setEditImage(e.target.value)}
                    onBlur={(e) => validateImageURL(e, "image_data")}
                    placeholder="Add input image URL here"
                    error={!!errors.editImage}
                    onFocus={() =>
                      setErrors((ini) => ({ ...ini, editImage: "" }))
                    }
                    helperText={errors.editImage}
                  />
                </div>
              )}
            </div>
          </Grid>
          <div className="w-full border-t-1 border-secondary mt-4" />
        </>
      )}

      {allowProcessType && promptData?.processType === "edit" && (
        <>
          <Grid item md={3.5}>
            <div className={"text-secondary font-semibold text-sm"}>
              Mask image
            </div>
            <div className={"text-tertiary text-sm"}>
              {t("what is mask image")}
            </div>
          </Grid>
          <Grid item md={8.5}>
            <div className="w-full flex flex-col gap-2 px-4 py-5 bg-secondary border-1 border-secondary rounded-lg ">
              <div className={"text-secondary font-semibold text-sm"}>
                Mask image
              </div>
              <Select
                size="small"
                fullWidth
                value={uploadType2}
                onChange={(e) => setUploadType2(e.target.value)}
                className="bg-primary"
                sx={{
                  borderRadius: "8px",
                }}
              >
                <MenuItem
                  sx={{
                    background: colors.borderSecondary,
                    color: colors.textSecondary,
                    "&:hover": {
                      background: colors.bgSecondary,
                    },
                  }}
                  value="file"
                >
                  Upload From Your Computer
                </MenuItem>
                {canAcceptImages && (
                  <MenuItem
                    sx={{
                      background: colors.borderSecondary,
                      color: colors.textSecondary,
                      "&:hover": {
                        background: colors.bgSecondary,
                      },
                    }}
                    value="url"
                  >
                    Add image from URL
                  </MenuItem>
                )}
              </Select>
              {uploadType2 == "file" && (
                <div className="w-full">
                  <FileUploadButton
                    id={"maskImage"}
                    value={maskImageFile}
                    onChange={handleUploadMaskImage}
                    accept={".png"}
                    variant={"primary"}
                  >
                    <span className="flex items-center gap-4 justify-center">
                      <FileUpload /> Select mask image
                    </span>
                  </FileUploadButton>
                </div>
              )}
              {uploadType2 === "url" && (
                <div className="w-full">
                  <FilledTextField
                    size="small"
                    fullWidth
                    value={maskImage}
                    onChange={(e) => setMaskImage(e.target.value)}
                    onBlur={(e) => validateImageURL(e, "mask_image")}
                    sx={{ background: "#3B3E4E" }}
                    placeholder="Add mask image URL here"
                    error={!!errors.maskImage}
                    onFocus={() =>
                      setErrors((ini) => ({ ...ini, maskImage: "" }))
                    }
                    helperText={errors.maskImage}
                  />
                </div>
              )}
            </div>
          </Grid>
          <div className="w-full border-t-1 border-secondary mt-4" />
        </>
      )}
      <Grid item md={3.5}>
        <div className={"text-secondary font-semibold text-sm"}>Prompt</div>
        <div className={"text-tertiary text-sm"}>{t("what is prompt")}</div>
      </Grid>
      <Grid item md={8.5}>
        <SuggestionField
          multiline={true}
          menuListItems={variableMenu}
          label={""}
          minRows={4}
          maxRows={5}
          placeholder={"Enter a description..."}
          value={basePrompt}
          onChange={(e, value = "") =>
            setBasePrompt(value ? value : e.target.value)
          }
          onBlur={handleBasePromptBlur}
        />
      </Grid>
      <div className="w-full border-t-1 border-secondary mt-4" />
      <Grid item md={3.5}>
        <div className={"text-secondary font-semibold text-sm"}>
          Adjust parameters
        </div>
        <div className={"text-tertiary text-sm"}>Define key-value pairs</div>
        <div className={"text-tertiary text-sm mt-2"}>
          {t("what is prompt key")}
        </div>
        <div className={"text-tertiary text-sm mt-2"}>
          {t("what is prompt value")}
        </div>
      </Grid>
      <Grid item md={8.5}>
        {promptList?.length > 1 &&
          promptList?.map((data, index) => (
            <PromptBar key={index} data={data} index={index} />
          ))}
        <div className="mt-4 flex  items-center justify-end text-center">
          <Button
            onClick={handleAddMorePrompt}
            startIcon={<AddIcon />}
            variant="outlined"
            color="secondary"
          >
            Add parameter
          </Button>
        </div>
      </Grid>
      <div className="w-full border-t-1 border-secondary mt-4" />
      <Grid item md={3.5}>
        <div className={"text-secondary font-semibold text-sm"}>
          System Prompt
        </div>
        <div className={"text-tertiary text-sm"}>
          This is a prompt that is meant to provide contextual information and
          join the main prompt above. This is an optional field.
        </div>
      </Grid>
      <Grid item md={8.5}>
        {contextData.length > 0 ? (
          <>
            <div className="w-[30%]">
              <MuiSelect
                selectClassName="bg-primary"
                menuItems={contextData}
                showSelect={true}
                value={promptData?.context}
                onChange={(e) => {
                  let data = {
                    ...promptData,
                    context: e.target.value,
                  };
                  dispatch(handleChangEditPrompt(data));
                }}
                valueKey="id"
                labelKey="name"
              />
            </div>
            <div className="mt-[2%]">
              <TextField
                className="bg-secondary  rounded-md w-full p-2 "
                readOnly
                value={
                  contextData.length > 0
                    ? contextData.find(
                        (item) => item.id === promptData?.context
                      )?.context || ""
                    : "Loading..."
                }
              />
            </div>
          </>
        ) : (
          <div className="mt-4 flex items-center justify-end text-start">
            <p className="text-tertiary text-sm">
              No contextual prompts available at the moment. To create a new
              one, please use the context option in the left-side drawer
            </p>
          </div>
        )}
      </Grid>
      <div className="w-full border-t-1 border-secondary mt-4" />
      <Grid item md={3.5}>
        <div className={"text-secondary font-semibold text-sm"}>
          {t("advance settings")}
        </div>
        <div className={"text-tertiary text-sm"}>
          Configure cache to save credits.
        </div>
      </Grid>
      <Grid item md={8.5}>
        <Box
          display={!hideSettings ? "none" : "flex"}
          justifyContent={"end"}
          gap={2}
        >
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<ConfigureIcon />}
            onClick={() => setHideSettings(false)}
          >
            Configure
          </Button>
        </Box>
        <Box display={hideSettings ? "none" : "block"}>
          <PromptSettings />
        </Box>
      </Grid>
    </Grid>
  );
};

export default PromptData;
