import {
  getCollectionDashboardDetails,
  getCollectionDashboardErrors,
  getCollectionDashboardPrompmts,
  getPromptAnalytics,
  updateDashboardErrors,
} from "@/services/app/api";
import snackbar from "@/services/app/snackbar";
import { days, months, weeks } from "@/services/constants";
import { formatCurrency, formatNumber } from "@/services/generalFunctions";
import { handleLoading } from "@/store/collectionsSlice";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { BarChart, LineChart, PieChart } from "@mui/x-charts";
import { useThemeContext } from "@Themes/ThemeContextProvider";
import { ErrorOrWarningIcon } from "@UI/IconPack";
import MuiSelect from "@UI/MuiSelect";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const WEEKS = ["Week 1", "Week 2", "Week 3", "Week 4", "Week 5"];

const DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const Title = ({ children, className = "" }) => (
  <p
    className={`text-sm font-medium text-tertiary uppercase pb-5 ${className}`}
  >
    {children}
  </p>
);
const Label = ({ className = "", children }) => (
  <p className={`text-sm  text-primary  ${className}`}>{children}</p>
);
const Value = ({ className = "", children }) => (
  <p className={`text-2xl text-primary  ${className}`}>{children}</p>
);

const CollectionDashboard = () => {
  const { collectionId, isActive = false } = useParams();
  const { mode, colors } = useThemeContext();
  const [selectedTime, setSelectedTime] = useState("day");
  const [titles, setTitles] = useState([]);
  const [selectedTitle, setSelectedTitle] = useState(null);
  const [summary, setSummary] = useState([]);
  const [promptData, SetPromptData] = useState([]);
  const [promptId, setPromptId] = useState(null);
  const [promptDetails, setPromptDetails] = useState([]);
  const [averageResults, setAverageResults] = useState({});
  const [elementNames, setElementNames] = useState([]);
  const [totalPromptCount, setTotalPromptCount] = useState(0);
  const [lineGraphData, setLineGraphData] = useState([]);
  const lineGraphXAxisData = lineGraphData?.map(({ x }) => x) || [];
  const lineGraphYAxisData = lineGraphData?.map(({ y }) => y) || [];
  const [errors, setErrors] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const collectionName =
    location.state?.collection?.name || "Untitled Collection";
  const pieChartColors =
    mode === "dark"
      ? ["#9F7AEA", "#333741", "#7A5AF8"]
      : ["#6938EF", "#EAECF0", "#7A5AF8"];

  const xAxisLabel = {
    day: "Hours",
    week: "Days",
    month: "Weeks",
    year: "Months",
    all_time: "Years",
  };

  const menuList = [
    { value: "day", label: "Today" },
    { value: "week", label: "7 days" },
    { value: "month", label: "Last 30 days" },
    { value: "year", label: "Last year" },
    { value: "all_time", label: "All time" },
  ];

  const formatTimestamp = (timestamp) => {
    const time = new Date(timestamp);

    switch (selectedTime) {
      case "day":
        let hour = time.getHours();
        const period = hour >= 12 ? "PM" : "AM";
        hour = hour % 12 || 12;
        return `${hour}${period}`;

      case "week":
        const dayIndex = (time.getDay() + 7) % 7;
        return days[dayIndex];

      case "month":
        const firstDayOfMonth = new Date(
          time.getFullYear(),
          time.getMonth(),
          1
        );

        const firstSundayOffset = firstDayOfMonth.getDay();

        const dayOfMonth = time.getDate();

        const adjustedDayOfMonth = dayOfMonth + firstSundayOffset;

        const weekNumber = Math.ceil(adjustedDayOfMonth / 7);

        return weeks[weekNumber - 1];

      case "year":
        return months[time.getMonth()];

      case "all_time":
        return time.getFullYear();

      default:
        return timestamp;
    }
  };

  const generateAverageByTimestamp = (timestamp, data) => {
    const today = new Date();
    let filteredData = [];

    switch (timestamp) {
      case "day":
        filteredData = data.filter((item) => {
          const itemDate = new Date(item.timestamp);
          return itemDate.getDate() === today.getDate();
        });
        break;

      case "week":
        const currentDayOfWeek = today.getDay();
        const daysToMonday = (currentDayOfWeek + 6) % 7;
        const startOfWeek = new Date(today);
        startOfWeek.setDate(today.getDate() - daysToMonday);

        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(startOfWeek.getDate() + 6);

        filteredData = data.filter((item) => {
          const itemDate = new Date(item.timestamp);
          return itemDate >= startOfWeek && itemDate <= endOfWeek;
        });
        break;

      case "month":
        filteredData = data.filter((item) => {
          const itemDate = new Date(item.timestamp);
          return (
            itemDate.getMonth() === today.getMonth() &&
            itemDate.getFullYear() === today.getFullYear()
          );
        });
        break;

      case "year":
        filteredData = data.filter((item) => {
          const itemDate = new Date(item.timestamp);
          return itemDate.getFullYear() === today.getFullYear();
        });
        break;

      case "all_time":
        filteredData = data;
        break;

      default:
        return null;
    }

    if (filteredData.length === 0) {
      setLineGraphData([]);
      setSummary({});
      return;
    }

    const averageResult = {};
    const properties = Object.keys(filteredData[0].result);

    properties.forEach((property) => {
      averageResult[property] = filteredData.reduce(
        (acc, curr) => acc + (curr.result[property] || 0),
        0
      );
      if (property === "response_time") {
        averageResult[property] /= filteredData.length;
      }
    });

    const sortedData = filteredData
      .map((item) => ({
        ...item,
        timestamp: new Date(item.timestamp),
      }))
      .sort((a, b) => a.timestamp - b.timestamp);

    const chartData = sortedData.map((item) => ({
      x: formatTimestamp(item.timestamp),
      y: item.result?.tokens || 0,
    }));

    setLineGraphData(chartData);
    setSummary(averageResult);
  };

  const fetchAnalytics = async () => {
    const params = { time: selectedTime };
    const payload = { collection_id: collectionId, params };

    try {
      dispatch(handleLoading(true));

      const [apiResponse, promptsResponse] = await Promise.all([
        getCollectionDashboardDetails(payload),
        getCollectionDashboardPrompmts(collectionId),
      ]);

      if (apiResponse?.items?.length) {
        generateAverageByTimestamp(selectedTime, apiResponse.items);
      } else {
        setLineGraphData([]);
        setSummary({});
      }

      if (promptsResponse?.items?.length) {
        const titles = promptsResponse.items.map(
          (item) => Object.keys(item)[0]
        );
        setTitles(titles);
        setTotalPromptCount(
          promptsResponse.items.reduce(
            (count, item) => count + Object.values(item).flat().length,
            0
          )
        );
        SetPromptData(promptsResponse.items);
        console.log("prompt: ", promptsResponse.items);
      }
    } catch (error) {
      snackbar.error({
        title: error?.error?.toString() || "Something went wrong!",
      });
    } finally {
      dispatch(handleLoading(false));
    }
  };

  const fetchErrors = async () => {
    try {
      const response = await getCollectionDashboardErrors(collectionId);
      setErrors(response?.items || []);
    } catch (error) {
      snackbar.error({ title: error?.error?.toString() });
    }
  };

  const calculateAverage = (data) => {
    const averageResult = {};

    if (data.length > 0) {
      const properties = Object.keys(data[0].result);
      properties.forEach((property) => {
        averageResult[property] = data.reduce(
          (acc, curr) => acc + (curr.result[property] || 0),
          0
        );
      });
    }

    return averageResult;
  };

  const fetchPromptData = async (firstPromptId) => {
    const params = { time: selectedTime };
    const payload = { prompt_id: firstPromptId, params };

    try {
      dispatch(handleLoading(true));
      const response = await getPromptAnalytics(payload);

      if (response?.items?.length > 0) {
        setPromptDetails(response.items);
        setAverageResults(calculateAverage(response.items));
      }
    } catch (error) {
      snackbar.error({ title: error?.error?.toString() });
    } finally {
      dispatch(handleLoading(false));
    }
  };

  const handleTitleClick = (title) => {
    const selectedItem = promptData.find(
      (item) => Object.keys(item)[0] === title
    );

    if (selectedItem) {
      const nameArray = selectedItem[title].map((element) => element.name);
      setElementNames(nameArray);
      setSelectedTitle(title);

      const firstElementId = selectedItem[title][0]?.id;
      if (firstElementId) {
        setPromptId(firstElementId);
        fetchPromptData(firstElementId);
      }
    }
  };

  const handleElementNameClick = (name) => {
    const selectedItem = promptData.find((item) => {
      const title = Object.keys(item)[0];
      return item[title].some((element) => element.name === name);
    });

    if (selectedItem) {
      const title = Object.keys(selectedItem)[0];
      const element = selectedItem[title].find(
        (element) => element.name === name
      );
      if (element?.id) {
        setPromptId(element.id);
        fetchPromptData(element.id);
      }
    }
  };

  const handleDismiss = (errorId) => {
    dispatch(handleLoading(true));
    const data = {
      error_dismiss: true,
    };

    updateDashboardErrors({ error_id: errorId, data })
      .then((res) => {
        fetchErrors();
      })
      .catch((error) => {
        snackbar.error({
          title: `${error.error && error.error.toString()}`,
        });
      })
      .finally(() => dispatch(handleLoading(false)));
  };

  useEffect(() => {
    fetchAnalytics();
    fetchErrors();
  }, [selectedTime, collectionId]);

  useEffect(() => {
    if (promptData.length > 0) {
      const firstTitle = Object.keys(promptData[0])[0];
      setTitles(promptData.map((item) => Object.keys(item)[0]));
      setSelectedTitle(firstTitle);

      const firstPromptId = promptData[0][firstTitle][0]?.id;
      setPromptId(firstPromptId);

      if (firstPromptId) {
        fetchPromptData(firstPromptId);
        handleTitleClick(firstTitle);
      }
    }
  }, [promptData]);

  // const handleGoDown = () => {
  //   window.scrollTo({
  //     top: document.body.scrollHeight + 600,
  //     behavior: "smooth",
  //   });
  // };

  const defaultResult = { result: { tokens: 0 } };

  const getMatchingItem = (promptDetails, matchFn) =>
    promptDetails.find(matchFn) || defaultResult;

  const generateXLabels = (length, formatFn) =>
    Array.from({ length }, (_, index) => formatFn(index));

  const getData = () => {
    const now = new Date();
    let XLabels = [];
    let today = new Date();

    switch (selectedTime) {
      case "year":
        XLabels = generateXLabels(12, (index) => {
          const monthTime = new Date();
          monthTime.setMonth(today.getMonth() - (11 - index));
          return MONTHS[monthTime.getMonth()];
        });

        const filledYearData = generateXLabels(12, (index) => {
          const monthTime = new Date();
          monthTime.setMonth(today.getMonth() - (11 - index));
          return getMatchingItem(promptDetails, (item) => {
            const itemDate = new Date(item.timestamp);
            return (
              itemDate.getMonth() === monthTime.getMonth() &&
              itemDate.getFullYear() === monthTime.getFullYear()
            );
          });
        });

        return { data: filledYearData, XLabels };

      case "month":
        const firstDayOfMonth = new Date(
          today.getFullYear(),
          today.getMonth(),
          1
        );

        const getWeekOfMonth = (date) => {
          const dayOfMonth = date.getDate();
          const dayOfWeek = firstDayOfMonth.getDay();
          const adjustedDayOfMonth = dayOfMonth + dayOfWeek;
          return Math.ceil(adjustedDayOfMonth / 7);
        };

        const currentWeek = getWeekOfMonth(today);

        XLabels = generateXLabels(currentWeek, (index) => `Week ${index + 1}`);

        const filledMonthData = generateXLabels(currentWeek, (index) => {
          const weekStart = new Date(firstDayOfMonth);
          weekStart.setDate(
            firstDayOfMonth.getDate() + index * 7 - firstDayOfMonth.getDay()
          );

          const weekEnd = new Date(weekStart);
          weekEnd.setDate(weekStart.getDate() + 6);

          return getMatchingItem(promptDetails, (item) => {
            const itemDate = new Date(item.timestamp);
            return itemDate >= weekStart && itemDate <= weekEnd;
          });
        });

        return { data: filledMonthData, XLabels };

      case "week":
        XLabels = generateXLabels(7, (index) => {
          const dayTime = new Date();
          dayTime.setDate(dayTime.getDate() - (6 - index));
          return DAYS[dayTime.getDay()];
        });

        const filledWeekData = generateXLabels(7, (index) => {
          const dayTime = new Date();
          dayTime.setDate(dayTime.getDate() - (6 - index));
          return getMatchingItem(promptDetails, (item) => {
            const itemDate = new Date(item.timestamp);
            return (
              itemDate.getDate() === dayTime.getDate() &&
              itemDate.getMonth() === dayTime.getMonth() &&
              itemDate.getFullYear() === dayTime.getFullYear()
            );
          });
        });

        return { data: filledWeekData, XLabels };

      case "day":
        XLabels = generateXLabels(24, (index) => {
          const hourTime = new Date();
          hourTime.setHours(hourTime.getHours() - (23 - index));
          let hour = hourTime.getHours();
          let period = hour >= 12 ? "PM" : "AM";
          hour = hour % 12 || 12;
          return `${hour}${period}`;
        });

        const filledData = generateXLabels(24, (index) => {
          const hourTime = new Date();
          hourTime.setHours(hourTime.getHours() - (23 - index));

          return getMatchingItem(promptDetails, (item) => {
            const itemDate = new Date(item.timestamp);

            const currentItemHour = Math.floor(
              itemDate.getTime() / (1000 * 60 * 60)
            );
            const targetHour = Math.floor(
              hourTime.getTime() / (1000 * 60 * 60)
            );

            return currentItemHour === targetHour;
          });
        });

        return { data: filledData, XLabels };

      default:
        return { data: promptDetails, XLabels: [] };
    }
  };

  const { data, XLabels } = getData();

  const tokens = data.map((item) => (item.result ? item.result.tokens : 0)) || [
    0,
  ];

  const xLabels = XLabels.map((item) => item) || [0];

  return (
    <>
      <div className="flex flex-col justify-between items-start px-[6%] md:space-y-4 w-full mt-[4%]">
        <h1 className="text-primary display-md-semibold ">{collectionName}</h1>

        <div className="flex items-center">
          <h1
            className="text-primary cursor-pointer text-md"
            onClick={() => navigate(-1)}
          >
            Collection
          </h1>
          <KeyboardArrowRightIcon />
          <h1 className="text-primary text-md py-1 px-3 rounded-lg bg-secondary">
            Dashboard
          </h1>
        </div>
      </div>
      <div className="flex flex-col overflow-auto mx-[5%] gap-y-4 items-center mt-[2%]">
        <div className=" w-full    bg-secondary border-1 rounded-lg border-secondary p-5">
          <div className="flex gap-4 justify-between ">
            <div>
              {isActive && (
                <div className="flex items-center gap-2 py-1 text-sm border-1 text-green-900 dark:text-green-300  bg-green-400 dark:bg-green-950  border-green-500 dark:border-green-800 px-2 rounded-full">
                  <div className="h-[6px] w-[6px] rounded-full bg-[#17B26A] "></div>{" "}
                  Active
                </div>
              )}
            </div>
            <div className="w-40">
              <MuiSelect
                menuItems={menuList}
                value={selectedTime}
                onChange={(e) => setSelectedTime(e.target.value)}
              />
            </div>
          </div>
          <Title>{t("overview")}</Title>
          <div className="flex gap-24 lg:gap-32 border-b-2 p-4 border-secondary overflow-auto">
            <div>
              <Label>Prompts</Label>
              <Value>
                {promptData?.length ? formatNumber(totalPromptCount) : "0"}
              </Value>
            </div>
            <div>
              <Label>Number of responses</Label>
              <Value>
                {(summary?.no_of_response &&
                  formatNumber(summary?.no_of_response)) ||
                  "0"}
              </Value>
            </div>
            <div>
              <Label>Tokens consumed</Label>
              <div className="flex items-end gap-1">
                <Value>{formatNumber(summary?.tokens || 0)}</Value>{" "}
                <span className="text-base pb-[2px]">
                  {(summary?.cost &&
                    formatCurrency(summary?.cost?.toFixed(1))) ||
                    ""}
                </span>
              </div>
            </div>
            <div>
              <Label>Avg. response time</Label>
              <div className="flex items-end gap-1">
                <Value>{summary?.avg_response_time?.toFixed(1) || "0"}</Value>{" "}
                <span className="text-base pb-[2px]">Seconds</span>
              </div>
            </div>
            <div>
              <Label>Errors/Issues</Label>
              <div className="flex gap-2">
                <Value>
                  {(summary?.total_failure &&
                    formatNumber(summary?.total_failure)) ||
                    "0"}
                </Value>
                {/* {!!summary?.total_failure && (
                  <div
                    onClick={handleGoDown}
                    className="cursor-pointer max-h-[44px] w-[114px] flex items-center gap-2 border-1 border-yellow-800 bg-yellow-950 text-sm text-yellow-500 py-1 px-2 rounded-full "
                  >
                    See details{" "}
                    <GoDownIcon color={"#F79009"} height={8} width={8} />{" "}
                  </div>
                )} */}
              </div>
            </div>
          </div>

          <Title className="p-4">{t("tokens consumed")}</Title>
          <div className="relative overflow-auto flex gap-4 justify-between">
            {lineGraphYAxisData?.length > 0 ? (
              <div className="ml-8">
                <LineChart
                  width={collectionId ? 780 : 720}
                  height={300}
                  sx={{
                    "& .MuiAreaElement-root": {
                      fill: "url('#myGradient')",
                    },
                    "& .MuiChartsAxis-tick": {
                      strokeWidth: "0",
                    },

                    "& .MuiChartsAxis-left .MuiChartsAxis-tickLabel": {
                      strokeWidth: "0",
                      fill: colors?.tertiary,
                    },

                    "& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel": {
                      strokeWidth: "0",
                      fill: colors?.tertiary,
                    },

                    "& .MuiChartsAxis-bottom .MuiChartsAxis-line": {
                      fill: colors?.tertiary,
                      strokeWidth: 0,
                    },

                    "& .MuiChartsAxis-left .MuiChartsAxis-line": {
                      fill: colors?.tertiary,
                      strokeWidth: 0,
                    },
                  }}
                  series={[
                    {
                      data: lineGraphYAxisData,
                      type: "line",
                      label: "Token",
                      area: true,
                      stack: "total",
                      showMark: false,
                      color: mode === "dark" ? "#9F7AEB" : "#6938EF",
                    },
                  ]}
                  xAxis={[
                    {
                      scaleType: "point",
                      data: lineGraphXAxisData,
                    },
                  ]}
                  slotProps={{
                    legend: {
                      hidden: true,
                    },
                  }}
                >
                  {" "}
                  <defs>
                    <linearGradient
                      id="myGradient"
                      gradientTransform="rotate(90)"
                    >
                      <stop
                        offset="0%"
                        style={{ stopColor: "rgba(155,138,251,1)" }}
                      />
                      <stop
                        offset="90%"
                        style={{
                          stopColor:
                            mode === "dark"
                              ? "rgba(0,0,0,0.1)"
                              : "rgba(255,255,255,0.2)",
                        }}
                      />
                      <stop
                        offset="100%"
                        style={{
                          stopColor:
                            mode === "dark"
                              ? "rgba(0,0,0,0)"
                              : "rgba(255,255,255,0)",
                        }}
                      />
                    </linearGradient>
                  </defs>
                </LineChart>

                <div className="text-sm text-tertiary text-center">
                  {xAxisLabel[selectedTime] || ""}
                </div>
              </div>
            ) : (
              <div className="text-center text-primary h-40 flex items-center w-80 justify-center">
                <div>No data available</div>
              </div>
            )}

            <div>
              {summary?.no_of_response == 0 && summary?.total_failure == 0 ? (
                <div className="text-center text-primary h-40 flex items-center w-80 justify-center">
                  <div>No data available</div>
                </div>
              ) : (
                <PieChart
                  colors={pieChartColors}
                  sx={{
                    "& .MuiPieArc-root": {
                      strokeWidth: 0,
                    },
                  }}
                  series={[
                    {
                      data: [
                        {
                          id: 0,
                          value: summary?.no_of_response,
                          label: "Success",
                        },
                        {
                          id: 1,
                          value: summary?.total_failure,
                          label: "Failed",
                        },
                      ],
                      innerRadius: 70,
                      outerRadius: 100,
                      paddingAngle: 0,
                      cornerRadius: 0,
                      startAngle: 0,
                      endAngle: 360,
                      cx: 148,
                      cy: 150,
                    },
                  ]}
                  width={collectionId ? 400 : 350}
                  height={300}
                  slotProps={{
                    legend: {
                      labelStyle: {
                        tableLayout: "fixed",
                      },
                      direction: "row",
                      position: {
                        horizontal: "center",
                        vertical: "top",
                      },
                    },
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <div class=" bg-secondary w-full border-1 rounded-lg  border-secondary p-5">
          <Title>{t("breakdown by llm")}</Title>
          {titles.length > 0 && (
            <div className="flex flex-row space-x-4 border-b-2 overflow-x-auto border-secondary">
              {" "}
              {titles.map((item, i) => (
                <div
                  key={i}
                  className={`p-2 cursor-pointer  ${
                    selectedTitle === item
                      ? "text-primary  border-b-2 dark:border-white border-primary"
                      : "text-secondary"
                  }`}
                  onClick={() => handleTitleClick(item)}
                >
                  {item}
                </div>
              ))}
            </div>
          )}
          <Title className="pt-4">{t("overview")}</Title>
          <div className="flex justify-between pb-4 gap-24 lg:gap-32 border-b-2  border-secondary overflow-auto">
            <div>
              <Label>Avg. response time</Label>
              <div className="flex items-end gap-1">
                <Value>
                  {averageResults.avg_response_time?.toFixed(1) || "0"}
                </Value>{" "}
                <span className="text-base pb-[2px]">Seconds</span>
              </div>
            </div>
            <div>
              <Label>Input tokens</Label>
              <div className="flex items-end gap-1">
                <Value>{averageResults?.input_tokens || "0"}</Value>{" "}
              </div>
            </div>
            <div>
              <Label>Output tokens</Label>
              <div className="flex items-end gap-1">
                <Value>{averageResults?.output_tokens || "0"}</Value>{" "}
              </div>
            </div>
            <div>
              <Label>API calls</Label>
              <div className="flex items-end gap-1">
                <Value>{averageResults?.api_calls || "0"}</Value>{" "}
              </div>
            </div>
            <div>
              <Label>Cost</Label>
              <div className="flex items-end gap-1">
                <Value>
                  {(averageResults?.cost &&
                    formatCurrency(averageResults.cost)) ||
                    "0"}
                </Value>{" "}
              </div>
            </div>
          </div>
          <Title className="pt-4">{t("prompts using this llm")}</Title>
          <div className="relative  flex gap-4 justify-between">
            <div className="min-w-[200px] max-w-[300px] h-[312px] overflow-auto border-r py-4 pr-1 border-secondary">
              {elementNames.length > 0 && (
                <div className="flex flex-col  h-full">
                  {elementNames.map((name, index) => {
                    const selectedItem = promptData.find((item) => {
                      const title = Object.keys(item)[0];
                      return item[title].some(
                        (element) => element.name === name
                      );
                    });

                    const elementId = selectedItem
                      ? selectedItem[Object.keys(selectedItem)[0]].find(
                          (element) => element.name === name
                        )?.id
                      : null;

                    const isActive = elementId === promptId;

                    return (
                      <div
                        key={index}
                        className={`p-2 rounded-md mb-5 ${
                          isActive ? "bg-tertiary text-primary" : "text-primary"
                        }`}
                        onClick={() => handleElementNameClick(name)}
                      >
                        <h2 className="text-primary cursor-pointer">{name}</h2>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>

            {promptDetails.length > 0 && (
              <BarChart
                width={700}
                height={330}
                series={[
                  {
                    data: tokens,
                    label: "tokens",
                    color: mode === "dark" ? "#9F7AEB" : "#6938EF",
                  },
                ]}
                slotProps={{
                  legend: { hidden: true },
                }}
                xAxis={[
                  { data: xLabels, scaleType: "band", id: "point-x-axis" },
                ]}
                sx={{
                  "& .MuiChartsAxis-tick": {
                    strokeWidth: "0",
                  },

                  "& .MuiChartsAxis-left .MuiChartsAxis-tickLabel": {
                    strokeWidth: "0",
                    fill: colors?.tertiary,
                  },

                  "& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel": {
                    strokeWidth: "0",
                    fill: colors?.tertiary,
                  },

                  "& .MuiChartsAxis-bottom .MuiChartsAxis-line": {
                    fill: colors?.tertiary,
                    strokeWidth: 0,
                  },

                  "& .MuiChartsAxis-left .MuiChartsAxis-line": {
                    fill: colors?.tertiary,
                    strokeWidth: 0,
                  },
                }}
              />
            )}
          </div>
        </div>

        <div className="bg-secondary  w-full    p-5 border-1 rounded-lg border-secondary">
          <Title>{t("errors & issues")}</Title>
          <div className="flex flex-col gap-4 max-h-[300px] overflow-auto items-center justify-start">
            {errors?.length > 0 ? (
              errors?.map((item, index) => (
                <div
                  key={index}
                  className="bg-primary border-1  border-primary p-4 rounded-lg w-full"
                >
                  <div>
                    <ErrorOrWarningIcon color={true ? "#F04438" : "#F79009"} />
                  </div>

                  <p className="text-sm text-secondary">{item?.error}</p>
                  <div>
                    <div
                      onClick={() => handleDismiss(item?.id)}
                      className="text-sm font-semibold text-tertiary pl-0 p-2 cursor-pointer w-fit"
                    >
                      Dismiss
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-primary">No errors found</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CollectionDashboard;
